import React, { useState } from 'react';
import Layout from '../components/Layout';

import Sidebar from '../components/Sidebar';
import Classes from './index.module.css';

import arrow_right from '../assets/images/arrow_right.png';
import arrow_left from '../assets/images/arrow_left.png';
import { Link } from 'gatsby';
import satan_blocker from '../assets/images/satan-blocker.png';

const Love = () => {
  const [counter, setCounter] = useState(100);
  //increase counter
  const increase = () => {
    setCounter(count => count + 1);
  };

  //decrease counter
  const decrease = () => {
    setCounter(count => count - 1);
  };

  //reset counter
  const reset = () => {
    setCounter(100);
  };
  return (
    <Layout>
      <Sidebar showTabs="steps" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="top"
      >
        <ul>
          <li>
            <h4>Love For the Sake of Allah</h4>
            <h6> الحب في الله علاماته وفضله وشروطه</h6>
          </li>
          <p />
          <ol>
            <p />
            <li>
              {' '}
              <h5>Importance of Good Company</h5>
            </li>
            <ul>
              <li>
                <a href="https://youtu.be/dBpM_5zaXfs">YouTube Recording </a>
              </li>
              <li>
                <a href="https://myislam.net/static/army.pdf">
                  Written PDF file to download
                </a>
              </li>
            </ul>
            <p />
            <li>
              {' '}
              <h5>
                Rewards
                <span className={Classes.blink_infinite}>
                  <img
                    className="img-fluid img-profile mx-auto mb-2,"
                    src={arrow_left}
                    alt=""
                    height="30"
                    width="30"
                  />
                </span>
                <span className={Classes.blink}>We are here</span>{' '}
                <span className={Classes.blink}>-6/3/22</span>
              </h5>
              <ul>
                <li>
                  <a href="https://youtu.be/U8ZlpEzGOng">YouTube Recording </a>
                </li>
              </ul>
            </li>
          </ol>

          <p />
          <h3>References</h3>
          <ul>
            <li>
              <a href="https://islamqa.info/ar/answers/138390/%D9%85%D8%A7-%D9%87%D9%8A-%D8%B5%D9%81%D8%A7%D8%AA-%D8%A7%D9%84%D8%B5%D8%AF%D9%8A%D9%82-%D8%A7%D9%84%D8%B5%D8%A7%D9%84%D8%AD">
                ما هي صفات الصديق الصالح؟
              </a>
            </li>
            <li>
              <a href="https://ar.islamway.net/article/45477/%D8%A7%D9%84%D8%AD%D8%A8-%D9%81%D9%8A-%D8%A7%D9%84%D9%84%D9%87-%D8%B9%D9%84%D8%A7%D9%85%D8%A7%D8%AA%D9%87-%D9%88%D9%81%D8%B6%D9%84%D9%87-%D9%88%D8%B4%D8%B1%D9%88%D8%B7%D9%87">
                الحب في الله علاماته وفضله وشروطه
              </a>
            </li>
            <p />
            <li>
              <a href="https://youtu.be/C4r5LjvcRGs">
                ٥- الصحبة الصالحة - كنوز رمضانية -عمرو خالد
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/results?search_query=%D8%A7%D9%84%D8%B5%D8%AD%D8%A8%D8%A9+%D8%A7%D9%84%D8%B5%D8%A7%D9%84%D8%AD%D8%A9+%D8%B9%D9%85%D8%B1%D9%88+%D8%AE%D8%A7%D9%84%D8%AF">
                ٥- الصحبة الصالحة - -عمرو خالد
              </a>
            </li>
            <p />
            <li>
              <a href="https://www.dorar.net/hadith/sharh/83784">
                الموسوعة الحديثية
              </a>
            </li>
            <p />
            <li>
              <a href="https://islamstory.com/ar/artical/22007/%D8%B9%D9%8A%D8%A7%D8%B4_%D8%A8%D9%86_%D8%A3%D8%A8%D9%8A_%D8%B1%D8%A8%D9%8A%D8%B9%D8%A9">
                عياش بن أبي ربيعة{' '}
              </a>
            </li>
            <p />
            <p />
            <li>
              <a href="http://quran.ksu.edu.sa/translations/english/1.html">
                َQuran
              </a>
            </li>
            <p />
            <li>
              <a href="https://islamqa.info/en/answers/216483/love-for-the-sake-of-allah-is-one-of-the-strongest-bonds-of-faith">
                Love for the sake of Allah is one of the strongest bonds of
                faith
              </a>
            </li>
            <li>
              <a href="https://understandquran.com/love-sake-allah/">
                Love For the Sake of Allah
              </a>
            </li>
            <li>
              <a href="https://sunnah.com/qudsi40:23">
                أَيْنَ الْمُتَحَابُّونَ بجَلَالِي{' '}
              </a>
            </li>
            <li>
              <a href="https://binbaz.org.sa/audios/2408/136-%D8%A8%D8%A7%D8%A8-%D9%81%D8%B6%D9%84-%D8%A7%D9%84%D8%AD%D8%A8-%D9%81%D9%8A-%D8%A7%D9%84%D9%84%D9%87-%D9%88%D8%A7%D9%84%D8%AD%D8%AB-%D8%B9%D9%84%D9%8A%D9%87-%D9%88%D8%A7%D8%B9%D9%84%D8%A7%D9%85-%D8%A7%D9%84%D8%B1%D8%AC%D9%84-%D9%85%D9%86-%D9%8A%D8%AD%D8%A8%D9%87-%D8%A7%D9%86%D9%87-%D9%8A%D8%AD%D8%A8%D9%87-%D9%88%D9%85%D8%A7%D8%B0%D8%A7-%D9%8A%D9%82%D9%88%D9%84-%D9%84%D9%87-%D8%A7%D8%B0%D8%A7-%D8%A7%D8%B9%D9%84%D9%85%D9%87">
                باب فضل الحبِّ في الله{' '}
              </a>
            </li>
            <li>
              <a href="https://www.islamweb.net/ar/article/229165/%D8%A7%D9%84%D8%AD%D8%A8-%D9%81%D9%8A-%D8%A7%D9%84%D9%84%D9%87">
                الحب في الله
              </a>
            </li>
            <li>
              <a href="https://sunnah.com/riyadussalihin:375">
                باب فضل الحب في الله{' '}
              </a>
            </li>
            <li>
              <a href="https://ar.wikipedia.org/wiki/%D8%B9%D9%82%D8%A8%D8%A9_%D8%A8%D9%86_%D8%A3%D8%A8%D9%8A_%D9%85%D8%B9%D9%8A%D8%B7">
                عقبة بن أبي معيط{' '}
              </a>
            </li>
          </ul>
        </ul>
      </section>
      <section></section>
    </Layout>
  );
};

export default Love;
